<template>
  <span>
    <label v-if="label">{{ localizedLabel }}</label>
    <p v-if="readonly" class="font-bold">{{currentLabel}}</p>
    <multiselect v-else v-model="selection" @input="onInput" v-bind="multiselectOptions" :class="cssClass"></multiselect>
  </span>
</template>
<script>
import Multiselect from 'vue-multiselect'
import {
  getObjByValue
} from '@/utils.js'

export default {
  name: 'BaseSelect',
  components: {
    Multiselect
  },
  props: {
    // must be included in props
    value: {
      type: null
    },
    label: {
      type: null
    },
    name: {
      type: String
    },
    options: {
      type: Array,
      required: true
    },
    blankLabel: {
      type: String,
      default: 'Select'
    },
    cssClass: {
      type: String,
      default: 'form-control'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      multiselectOptions: {
        name: name,
        options: this.options,
        label: 'label',
        trackBy: 'value',
        searchable: false,
        allowEmpty: false,
        selectLabel: '',
        selectedLabel: '',
        deselectLabel: ''
        //showLabels: false,
        //showPointer: false
      },
      selection: null,
    }
  },
  mounted() {
    this.setSelectionFromValue(this.value)
    if (this.options && (this.options.length > 0) && typeof(this.options[0].value) == 'number') {
      console.error(this.$options.name, 'The values in the options must be strings')
    }
  },
  computed: {
    hasValue() {
      return (this.selection && (this.selection.value.length > 0) )
    },
    currentLabel() {
      if (this.hasValue) {
        return this.selection.label
      }
    },
    localizedLabel() {
      if (this.$i18n) {
        return this.$t(this.name)
      }
      return this.label
    }
  },
  watch: {
    value(newV) {
      this.setSelectionFromValue(newV)
    }
  },
  methods: {
    setSelectionFromValue(value) {
      var normalizedV
      if (value || (typeof(value) == 'number')) {
        normalizedV = value.toString()
      } else {
        normalizedV = ''
      }
      const selected = getObjByValue(this.options, normalizedV)
      if (selected.value === null) {
        const prompt = { label: this.blankLabel, value: '' }
        this.selection = prompt
      } else {
        this.selection = selected
      }
    },
    onInput({ label, value }) {
      this.$emit('input', value)
    }
  }
}
</script>
